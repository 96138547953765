import { useMetadataAnimalBreeds } from "@igloo-be-omnipartners/hooks";
import { Dropdown, useInputProps } from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import { Locale } from "../../../../__generated__/globalTypes";

export const PetBreedSelector = ({ petType }: { petType: string }) => {
  const { loading, items } = useMetadataAnimalBreeds({
    lang: Locale.fr,
    type: petType,
  });

  const regularBreeds = items.filter(breed => !breed.isOther);

  return (
    <Dropdown<string>
      {...useInputProps({
        name: "breedId",
        disabled: loading,
      })}
      fullWidth
      items={regularBreeds}
    />
  );
};
