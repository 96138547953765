import { Redirect } from "@reach/router";
import {
  Button,
  DatePicker,
  Fieldset,
  Form,
  Input,
  LittersSubmit,
  Loader,
  PageTitle,
  RadioGroup,
  useButtonProps,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import {
  IconFemale16,
  IconMale16,
} from "@royalcanin-fr-loyalty/ui-kit/src/icons-ts";
import { addMonths, endOfDay, startOfDay } from "date-fns";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { date, number, object, string } from "yup";
import { Layout } from "../../components/Layout";
import { PetBreedSelector } from "../../components/PetBreedSelector";
import { useLitterCreate } from "../../lib/hooks/useLitters";
import { useUserRole } from "../../lib/hooks/useUserRole";
import { useLocationContext } from "../../lib/LocationProvider";
import { withPage } from "../../lib/withPage";

const petTypes = [
  {
    label: "Chien",
    value: "dog",
  },
  {
    label: "Chat",
    value: "cat",
  },
];
const numberInputConfig = {
  parse: (value: any) => parseInt(value, 10),
};

const LittersCreate = () => {
  const { navigate } = useLocationContext();
  const { litterCreate } = useLitterCreate();
  const { data, loading } = useUserRole();

  const title = (
    <PageTitle subTitle="Décrivez votre portée">Déclarer une portée</PageTitle>
  );

  if (loading) {
    return (
      <Layout>
        <Wrapper>
          {title}
          <Loader />
        </Wrapper>
      </Layout>
    );
  }

  if (!data?.isAuthorizedToCreateLitter && !loading) {
    return <Redirect noThrow to="/" />;
  }

  return (
    <Layout>
      <Wrapper>
        <Form
          onSubmit={async (values) => {
            const res = await litterCreate(values);

            if (res && res.result && res.result.id) {
              navigate("/litters/choice", {
                litter: res.result.id,
              });
            }
          }}
          fieldLabels={{
            breedId: "Race",
            males: "Mâles",
            females: "Femelles",
            dob: "Date de naissance",
          }}
          initialValues={{
            petType: petTypes[0].value,
            breedId: "",
            females: 0,
            males: 0,
            dob: new Date(),
          }}
          schema={object().shape({
            breedId: string().required(),
            dob: date()
              .min(addMonths(startOfDay(new Date()), -6))
              .max(addMonths(endOfDay(new Date()), 3))
              .required(),
            females: number().min(0).max(99).required(),
            males: number().min(0).max(99).required(),
          })}
          render={({ handleSubmit, values, useInputProps }) => (
            <form onSubmit={handleSubmit}>
              <Row center="md">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <RadioGroup
                        items={petTypes}
                        {...useInputProps({
                          name: "petType",
                        })}
                        type="radio"
                      >
                        Animal
                      </RadioGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Fieldset offset={15}>
                        <PetBreedSelector petType={values.petType} />
                      </Fieldset>
                      <Fieldset offset={15}>
                        <Input
                          {...useInputProps({
                            name: "females",
                            config: numberInputConfig,
                          })}
                          icon={<IconFemale16 height={16} />}
                          fullWidth
                          type="number"
                        />
                      </Fieldset>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Fieldset offset={15}>
                        <DatePicker {...useInputProps({ name: "dob" })} />
                      </Fieldset>
                      <Fieldset offset={15}>
                        <Input
                          {...useInputProps({
                            name: "males",
                            config: numberInputConfig,
                          })}
                          type="number"
                          icon={<IconMale16 height={16} />}
                          fullWidth
                        />
                      </Fieldset>
                    </Col>
                  </Row>
                  <LittersSubmit>
                    <Button type="submit" {...useButtonProps()}>
                      Continuer
                    </Button>
                  </LittersSubmit>
                </Col>
              </Row>
            </form>
          )}
        />
      </Wrapper>
    </Layout>
  );
};

export default withPage(LittersCreate, {
  title: "Portées",
});
